import styled from 'styled-components'
import pickColor from '../utlis/pickColor'

const H3 = styled.h3`
  font-family:"Larsseit-Bold";
  font-size:31px;
  line-height:35px; 
  padding-bottom:${props => props.offset ? props.offset : "10px"};
  color:${props => pickColor(props.color)};
  @media screen and (max-width:768px){
    font-size: 23px;
    line-height: 27px;
    }
`
export default H3;