import React from 'react'
import Layout from '../components/layout'
import Paragraph from '../components/paragraph'
import H3 from '../components/h3'
import { Section, Wrapper, BaseImage } from '../components/baseStyles'
import { withPrefix } from 'gatsby'
import onama from '../images/header/o_nama.svg'
import homepage from '../images/homepage/homepage.png'
import { FormattedMessage } from 'react-intl'
import SEO from '../components/seo'
import { blue } from '../utlis/colors'
import styled from 'styled-components'
import LocalisedLink from '../components/localisedLink'

const Alink = styled(LocalisedLink)`
  color: ${blue};
  text-decoration: none;
  font-size: 20px;

  &:hover {
    text-decoration: underline;
  }
`
const Section1 = styled(Section)`
  padding-top: 25px;
`

const AboutUs = ({ location, pageContext: { locale } }) => {
	const isHomePage = location.pathname === withPrefix('/')
	return (
		<Layout
			heroImage={onama}
			isHomePage={isHomePage}
			title={
				locale === 'hr'
					? 'Tvoj partner za uspješnu sezonu'
					: 'Your ideal partner in tourism!'
			}
			text={
				locale === 'hr'
					? 'Dugogodišnja suradnja s našim partnerima omogućila nam je da identificiramo ključne probleme i potrebe iznajmljivača u turizmu.'
					: 'Long-term cooperations with our partners have enabled us to identify crucial problems and needs of renters in tourism.'
			}
			locale={locale}
		>
			<SEO
				title={
					locale === 'hr'
						? 'O nama | Idealan partner za iduću sezonu | Litto za Partnere'
						: 'About Us | Your Ideal Partner In Tourism | Litto For Partners'
				}
				description={
					locale === 'hr'
						? 'Dugogodišnja suradnja s našim partnerima omogućila nam je da identificiramo ključne probleme i potrebe iznajmljivača u turizmu.'
						: 'Long-term cooperations with our partners have enabled us to identify key problems and potential needs of renters in tourism.'
				}
				keywords={['Litto']}
				lang={locale}
			/>
			<Wrapper style={{ position: 'relative' }}>
				<Section1>
					<Paragraph offset="35px" color="darkBlue">
						<FormattedMessage id="aboutusStoJeLittoText1" />
					</Paragraph>
				</Section1>
				<BaseImage src={homepage} alt="about us" />
				<Section>
					<H3>
						<FormattedMessage id="aboutusZastoBasLittoTitle" />
					</H3>
					<Paragraph offset="25px" color="darkBlue">
						<FormattedMessage id="aboutusZastoBasLittoText1" />
					</Paragraph>
					<Paragraph offset="50px" color="darkBlue">
						<FormattedMessage id="aboutusZastoBasLittoText2" />
					</Paragraph>
					<H3>
						<FormattedMessage id="aboutusMisijaTitle" />
					</H3>
					<Paragraph offset="25px" color="darkBlue">
						<FormattedMessage id="aboutusMisijaText1" />
					</Paragraph>
					<H3>
						<FormattedMessage id="aboutusGoalTitle" />
					</H3>
					<Paragraph offset="25px" color="darkBlue">
						<FormattedMessage id="aboutusGoalText" />
					</Paragraph>
					<div style={{ margin: "60px 0", background: "#eee", padding: "40px" }}>
						<H3>
							<FormattedMessage id="aboutusPartnerTitle" />
						</H3>
						<Paragraph color="darkBlue">
							<FormattedMessage id="aboutusPartnerText" />
						</Paragraph>
					</div>
				</Section>
			</Wrapper>
		</Layout>
	)
}

export default AboutUs
